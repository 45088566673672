import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Img from "gatsby-image"

export default function Blog({data}) {
	const sourcesAlexaArchitecture = [
		data.mobileImageAA.childImageSharp.fluid,
		{
			...data.desktopImageAA.childImageSharp.fluid,
			media: `(min-width:768px}`
		}
	]

	const sourcesConveration = [
		data.mobileImageCon.childImageSharp.fluid,
		{
			...data.desktopImageCon.childImageSharp.fluid,
			media: `(min-width:342px}`
		}
	]


	var intent_code = 
`{"intents": [{
  "intent": "NiceReplyIntent”,
    "slots": [{
      "name": "FIRSTNAME",
      "type": "AMAZON.US_FIRST_NAME”
    }]
}]}
`

	var skill_service_code = 
`var Alexa = require('alexa-sdk');
exports.handler = function(event, context, callback) {
	var alexa = Alexa.handler(event, context);
	alexa.registerHandlers(handlers);
	alexa.execute();
};
var handlers = {
	'LaunchRequest': function () {
	this.emit(':ask', "Hello!  What is your name?");
	},
	'NiceReplyIntent': function () {
	var currentName = this.event.request.intent.slots.FIRSTNAME.value;
	this.emit(':tell', "It is nice to meet you, " + currentName);
	}
};
`
	return (
		<Layout>
			<article className="blog">
				<header className="entry-header">
					<h1 className="entry-title">Amazon Alexa – An Introduction to Alexa Skills</h1>
					<div className="entry-meta">
						<span className="sep">Posted on </span>
						<time className="entry-date">July 15, 2017</time>
					</div>
				</header>
				<div className="entry-content">
					<p>“Alexa, turn on the lights”, “Alexa, what's the weather in Toronto?”, “Alexa, tell us a joke!”</p>
					<p>You might be wondering what I'm doing until you see the magic the Amazon <a href="https://www.amazon.com/Amazon-Echo-Bluetooth-Speaker-with-WiFi-Alexa/dp/B00X4WHP5E">Echo</a> can perform. You can ask this device anything, and it will try to fulfill all your wishes. At least any request that can be fulfilled via a web service call. And all of this magic is what Amazon calls Alexa Skills.</p>
					<h1>Alexa Skills</h1>
					<p>There are three main types of Alexa skills:</p>
					<ol>
						<li><strong>Flash Briefing Skills</strong>. This skill is essentially an RSS feed aggregator. If you have an RSS feed, you can create your very first skill within a few clicks. Users can then ask “Alexa, what's my flash briefing?” and she will verbally read all these feeds.</li>
						<li><strong>Smart Home Skills</strong>. Ever want to use your voice to control your smart home appliances? Well, you can very easily with this skills.</li>
						<li><strong>Custom Skills</strong>. And lastly, the most common and most generic skill, that can handle almost any type of request.</li>
					</ol>
					<p>Let's take a deeper dive into Custom Skills.</p>
					<h1>Custom Skills</h1>
					<p>Custom skills comprise of two main components:</p>
					<ol>
						<li><strong>Skill Interface:</strong> This allows us to map voice commands to actions, or what amazon calls, utterances into intents.</li>
						<li><strong>Skill Service:</strong> A publicly accessible web service that is going to execute those intents.</li>
					</ol>
					<h2>Alexa Skill Architecture</h2>
					<p>The Alexa Skill architecture is quite powerful in it's simple elegant design. The whole architecture lives within the cloud. There is actually no skills physically installed on any Alexa enabled device. As soon as I say “Alexa” followed by a voice command:</p>
					<ul>
						<li>The Echo will wake up on the keyword Alexa, and send my voice as an audio stream to the Alexa Web Service.</li>
						<li>The Alexa Web Service will process the audio stream and determine which skill and intent is being requested, and then call the corresponding Skill Service.</li>
						<li>The Skill Service will execute the intent and return a response back to Alexa Web Service.</li>
						<li>Alexa Web Service will convert that response back to an audio stream and send it to the Echo.</li>
					</ul>
					<div><Img fluid={sourcesAlexaArchitecture} alt="Alexa Skill Architecture" /></div>
					
					<p>As you can see from above, my voice is only sent to the Alexa Web Service. It will never be sent to the Skill Service. So as the skill developer, you will never have to worry about how to process natural language. Alexa will manage all voice processing for us, and she does an excellent job.</p>
					
					<p>There are numerous types of devices that can interact with Alexa, including <a href="https://developer.amazon.com/public/solutions/alexa/alexa-skills-kit/docs/providing-home-cards-for-the-amazon-alexa-app">graphical devices</a>. This allows us to build some really interesting verbal and graphical interactive experiences. In addition, Alexa Skill Service can be used by 3rd party developers. If you were building your own application with your own hardware, it is very easy to hook into Alexa for your voice user interface.</p>
					<h2>An Example Alexa Skill</h2>
					<p>Let's create our first skill!</p>

					<div><Img fluid={sourcesConveration} alt="Alexa Conversation Diagram" /></div>

					<h2>Configure Skill Interface</h2>
					<p>In order to configure the skill interface, we need to define three pieces of information:</p>

					<ol>
						<li>
							<p>Define an <strong>Invocation Name</strong>.</p>
							<p>An invocation name is simply the verbal name of our skill and it enables Alexa to distinguish our skill from all other skills. In our case, we gave our skill the very unique name called “The Greeter”</p>
						</li>

						<li>
							<p>Define <strong>Intents</strong>.</p>
							<p>An intent is an action based on a user's request. So in our example, since we have two user requests, we will need two intents.</p>
							<p>The first user request “Alexa, open the Greeter” launches the first intent called “LaunchRequest” intent. This is a default intent that comes with all skills and it essentially launches our skill.</p>
							<p>The second user request “My name is Patricia” triggers our second intent which is a custom intent we called “NiceReply”.</p>
						</li>
						<li>
							<p>Define <strong>Utterances</strong>.</p>
							<p>For each of our intents, we will need to define a list of utterances. Utterances are a sample list of phrases that will trigger our intents. In our example, the utterances “Alexa, open the Greeter” and “My name is Patricia” triggered both of our intents.</p>
						</li>
					</ol>
					<p>So now that we know what we need to define, let's see the actual code.</p>

					<div className="alexa-skills">
						<div className="section">INVOCATION NAME:</div>
						<div className="section"><ul><li><strong>GREETER</strong></li></ul></div>
						<div className="section">UTTERANCES:</div>
						<div className="section">
							<div>
								<ul>
									<li><b>NiceReplyIntent</b> My name is {'{'}<b>FIRSTNAME</b>{'}'}</li>
									<li><b>NiceReplyIntent</b> I am {'{'}<b>FIRSTNAME</b>{'}'}</li>
									<li><b>NiceReplyIntent</b> {'{'}<b>FIRSTNAME</b>{'}'}</li>
								</ul>
							</div>
						</div>
						<div className="section">INTENT SCHEMA:</div>
						<div className="section"><pre>{intent_code}</pre></div>
					</div>

					<p>As you can can see here, we've mapped some utterances to the NiceReply intent. We've also substituted my name with what is called a slot. Since we want our skill service to receive the user's first name, a slot allows us to pass this additional information. Furthermore, the intent schema defines the data type for that slot as AMAZON.US_FIRST_NAME. There are various data types, such as date, time and numeric, that we can use within our utterances to pass information to our skill service.</p>
					
					<h2>Configure Skill Service</h2>
					<p>Now that we have the skill interface defined, we move onto the skill service. The skill service needs to be a publicly accessible web service. The easiest way to make web service in the amazon world is to use Amazon Lambda. And conveniently, Alexa skills can integrate quite easily to Lambda.</p>
					<pre>{skill_service_code}</pre>

					<p>The Lambda above is written in node and the first line imports the alexa-sdk. The Alexa SDK is a library that we can use to help process the payload back and forth between Alexa Web Service and our Skill Service. The payload is quite simple, just a json or xml payload, so we don't necessarily need it. However, the SDK does make code a little easier to read.</p>

					<p>Next, we define the main lambda handler followed by two handlers for each of our intents. The LaunchRequest handler simply asks the user “Hello! What is your name?”. The NiceReplyIntent handler will fetch the FIRSTNAME slot value from the utterance and tell the user “It's nice to meet you”. There are various other types of responses an Alexa skill can return, including content marked as a <a href="https://developer.amazon.com/public/solutions/alexa/alexa-skills-kit/docs/providing-home-cards-for-the-amazon-alexa-app" target="_blank" rel="noreferrer">graphical card</a> of information and the ability to <a href="https://developer.amazon.com/public/solutions/alexa/alexa-skills-kit/docs/alexa-skills-kit-voice-design-best-practices#use-ssml-tags-to-modify-prosody" target="_blank" rel="noreferrer">change the pitch or speed of verbal content</a>.</p>

					<h1>Conclusion</h1>
					
					<p>So as you can see, Alexa skills are extremely easy to setup and configure. And most importantly, they are really fun to play with! I spent a few hours learning all the various components that were required to setup and configure my echo dot, and within an evening I had my very first (private) alexa skill that did all my bidding to take over the world. Or at the very least, she told me she loved me. <img className="emoji" alt="❤" src="/images/blogs/alexa-skills/heart.svg" /></p>
					
					<p>If you want to learn more, there are numerous online tutorials and resources available, including <a href="https://developer.amazon.com/public/solutions/alexa/alexa-skills-kit/docs/alexa-skills-kit-voice-design-best-practices" target="_blank" rel="noreferrer">Alexa Skills Kit Voice Design Best Practices</a> and <a href="https://developer.amazon.com/designing-for-voice/" target="_blank" rel="noreferrer">Alexa Voice Design Guide</a></p>
					
					<p>Happy Coding!</p>
				</div>
			</article>
		</Layout>
	)
}

export const query = graphql`
query {
	mobileImageAA: file(relativePath: { eq: "blogs/alexa-skills/alexa-skill-architecture.png" }) {
		childImageSharp {
			fluid(maxWidth: 581, quality: 100) {
				...GatsbyImageSharpFluid
				...GatsbyImageSharpFluidLimitPresentationSize
			}
		}
	}
	desktopImageAA: file(
		relativePath: { eq: "blogs/alexa-skills/alexa-skill-architecture.png" }
	) {
		childImageSharp {
			fluid(maxWidth: 681, quality: 100) {
				...GatsbyImageSharpFluid
				...GatsbyImageSharpFluidLimitPresentationSize
			}
		}
	}

	mobileImageCon: file(relativePath: { eq: "blogs/alexa-skills/alexa-conversation.png" }) {
		childImageSharp {
			fluid(maxWidth: 242, quality: 100) {
				...GatsbyImageSharpFluid
				...GatsbyImageSharpFluidLimitPresentationSize
			}
		}
	}
	desktopImageCon: file(
		relativePath: { eq: "blogs/alexa-skills/alexa-conversation.png" }
	) {
		childImageSharp {
			fluid(maxWidth: 342, quality: 100) {
				...GatsbyImageSharpFluid
				...GatsbyImageSharpFluidLimitPresentationSize
			}
		}
	}
}`
